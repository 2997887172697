import React from 'react';
import { makeStyles, Button } from "@material-ui/core";
import { theme } from '../styles/theme-constants';

const useStyles = makeStyles({
    back: {
        color: "white",
        textTransform: "none",
        padding: '0 40px',
        fontSize: '14px',
        fontWeight: '700',
        borderRadius: '5px',
        height: '42px',
        backgroundColor: theme.purpleButton,
        '&:hover': {
            backgroundColor: theme.white,
            border: `1px solid ${theme.purpleButton}`,
            color: theme.purpleButton,
            boxShadow: "rgba(0, 0, 0, 0.13) 0px 1px 1px"
        }
    },
    signUp: {
        color: "white",
        textTransform: "none",
        padding: '6px 8px',
        fontSize: '14px',
        height: '42px',
        width: '100%',
        fontWeight: 'bold',
        borderRadius: '5px',
        backgroundColor: theme.primary,
        '&:hover': {
            backgroundColor: theme.white,
            border: `1px solid ${theme.primary}`,
            color: theme.primary,
            boxShadow: "rgba(0, 0, 0, 0.13) 0px 1px 1px"
        },
        "&:disabled": {
            backgroundColor: theme.grayLight,
            color: theme.gray
        }
    },
    openCalls: {
        backgroundColor: theme.white, 
        color: '#57BEC9',
        border: `2px solid #57BEC9`,
        textTransform: "none",
        height: '42px',
        width: '100%',
        fontSize: '14px',
        fontWeight: 'bold',
        boxShadow: "rgba(0, 0, 0, 0.13) 0px 1px 1px",
        "&:hover": {
            backgroundColor: '#57BEC9',
            color: theme.white,
        },
    }
});

const GeneralButton = ({ button, disabled, width, fontSize, type, href }) => {
    const classes = useStyles();

    const iconPosition = button.iconPosition || 'left';

    const buttonClasses = {
        back: classes.back,
        signUp: classes.signUp,
        openCalls: classes.openCalls
    };
    
    return (
        <Button
            disabled={disabled}
            type={type || "button"}
            id="btn-general"
            href={href || ""}
            color="primary"
            className={buttonClasses[button.type] || classes.signUp}
            onClick={button.func || null}
            style={{
                width: width || "auto",
                fontSize: fontSize || "14px",
            }}
            startIcon={iconPosition === 'left' && button.icon}
            endIcon={iconPosition === 'right' && button.icon}
        >
            {button.text}
        </Button>
    );
}

export default GeneralButton;
